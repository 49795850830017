import ajax from "../ajax";
import marketing from "../marketing";
import setLinkTarget from './behavior/set-link-target';
import userTimings from '../user-timings';
import prebid from './analytics/prebid';
import expandableMenu from './expandable-menu/expandable-menu';
import toggleComments from './comments/toggle-comments';
import menuRollup from './menu/menu-rollup';
import browseMenuTabs from './browse-menu-tabs/browse-menu-tabs';
import eventRegistration from './form-submit/event-registration';
import detailLibrary from './detail-library/detail-library';
import inlineEmailModule from './form-submit/inline-email-module';
import newsletterSplalsh from './form-submit/newsletter-signup-form';
import accountPanel from "./account-panel/account-panel";

ajax();
marketing();
setLinkTarget();
userTimings();
expandableMenu();
toggleComments();
menuRollup();
browseMenuTabs();
eventRegistration();
inlineEmailModule();
detailLibrary();
newsletterSplalsh();
accountPanel();

document.addEventListener('DOMContentLoaded', () => {
  prebid();
});
