import $ from "jquery";

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    // toggle comments - visible only on mobile
    const $commentsWrapper = $('.comments');
    $('body').on('click', '.comments__trigger', function(e) {
      e.preventDefault();
      if( !$commentsWrapper.hasClass('open') ) {
        $.publish('GBA.comments.show');
      }
      $commentsWrapper.toggleClass('open');
    });
  });
}
