export default () => {


  const form = document.querySelector('#newsletter-signup-splashpage');

  if (!form) return;

  const eleEmail = form.querySelector('input[type=email]') as HTMLInputElement | null;
  const eleFName = form.querySelector('input[id=firstname]') as HTMLInputElement | null;
  const eleLName = form.querySelector('input[id=lastname]') as HTMLInputElement | null;
  const eleChecks = form.querySelectorAll('.checkbox--fake');
  const eleCheckWrap = form.querySelector('.checkbox_wrapper') as HTMLDivElement | null;

  function createError(name, msg) {
    let l = document.createElement("label");
    l.classList.add("inline-error");
    l.setAttribute("for", name);
    l.setAttribute("id",name+"-error");
    l.setAttribute("name","inline-error");
    l.innerHTML = msg;
    return l
  }
  function removeError(name) {
    let error = form.querySelector('#'+name+"-error");
    if (error) {
      error.remove();
    }
  }
  function removeErrors(){
    let errors = form.querySelectorAll('.inline-error');
    [].forEach.call(errors, (error) => {
      error.remove();
    });
  }

  eleEmail.addEventListener('focus', (e) => {
    removeError(eleEmail.id);
  });

  eleFName.addEventListener('focus', (e) => {
    removeError(eleFName.id);
  });

  eleLName.addEventListener('focus', (e) => {
    removeError(eleLName.id);
  });

  [].forEach.call(eleChecks, (check) => {
    check.addEventListener('click', () => {
      removeError(eleCheckWrap.id);
    });
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    removeErrors();

    let isValid = true;
    // Temporary email validation
    if ( !eleEmail.value.match(/(.+)@(.+){2,}\.(.+){2,}/) ) {
      eleEmail.after(createError(eleEmail.id,"Email is required and must contain @ and ."));
      isValid = false;
    }

    if ( !eleFName.value || eleFName.value.length == 0 ) {
      eleFName.after(createError(eleFName.id,"Please enter your first name."));
      isValid = false;
    }

    if ( !eleLName.value || eleLName.value.length == 0 ) {
      eleLName.after(createError(eleLName.id,"Please enter your last name."));
      isValid = false;
    }

    const eleChecked = form.querySelectorAll('input[type=checkbox]:checked');
    if (eleChecked.length === 0) {
      eleCheckWrap.append(createError(eleCheckWrap.id,"Please choose at least one eNewsletter."));
      isValid = false
    }

    if (! isValid ) return;


    form.querySelector('.formfield__submit').parentElement.classList.add('loading');

    import('./reCaptchaValidate').then(({default: validate}) => {
      validate(form.dataset.placement)
      .then((result) => {
        import('./process-email-form').then(({default: processForm}) => {
          processForm(form).then((result) => {
            const eleThankyou = document.querySelector('.enews-splash__thank-you') as HTMLDivElement | null;
            form.remove();
            eleThankyou.style.display = "block";
          })
          .catch((result) => {
            form.innerHTML = '<p>There was an error submitting your email.  Please contact customer sesrvice to sign up.</p>';
            console.log(result);
          });
        });
      })
      .catch((result) => {
        form.innerHTML = '<p class="signup-error">Spammer Detected!</p>';
        console.log(result);
      });
    });
  });
}
