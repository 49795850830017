export default (results) => {
  const templateEl: HTMLElement = document.getElementById('detail-library-search-results');

  if(!templateEl) {
    return;
  }
  const template = _.template(templateEl.innerHTML);
  const container: HTMLElement = document.querySelector('.detail-library__modal__results');
  container.innerHTML += template({items: results});

  if(results.current_page && parseInt(results.current_page) !== 1) {
    document.querySelector(`[data-results-page="${results.current_page}"]`).scrollIntoView(true);
  }
}
