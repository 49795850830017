import $ from "jquery";

export default () => {
  $(function() {
    const $body: JQuery = $('body');
    const $browseTabMenu: JQuery = $('.browse-tab-nav-menu__item');
    const $browseTabMenuContent: JQuery = $('.browse-tab-nav-content');

    $browseTabMenu.find('.active').attr('aria-selected', 'true');
    $browseTabMenuContent.find('.active').attr('tabindex', '0');

    $body.on('click', '.browse-tab-nav-menu__item a', function(e) {
      e.preventDefault();
      const $this: JQuery = $(this);
      const href: string = $this.attr('href');

      $browseTabMenu.find('.active').removeClass('active').attr('aria-selected', 'false');
      $browseTabMenuContent.find('.active').removeClass('active').attr('tabindex', '-1');
      $this.addClass('active').attr('aria-selected', 'true');
      $(href).addClass('active').attr('tabindex', '0').focus();
    });
  });
}
