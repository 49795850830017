import $ from 'jquery';

export default () => {

  function handleExpandableMenu() {
    const $body: JQuery = $('body'),
      $html: JQuery = $('html'),
      $expandableMenu: JQuery = $('[data-behavior="expandable-menu"]');

    $body.on('click', '[data-behavior="expandable-menu-trigger"]', function (e) {
      e.preventDefault();
      $body.off('click.moremenu'); // clean up previous event handlers if any

      const $this: JQuery = $(e.target);

      let $container: JQuery = $(e.target).parent();
      let $moreDropdown: JQuery = $this.next('ul');

      // handle case where the image wrapped in `<a>` is the target
      // in the user account options menu
      if($this.hasClass('siteheader__actions__member__name')) {
        $container = $container.parent();
        $moreDropdown = $this.parent().next('ul');
      }

      if ($container.hasClass('menu-open')) {
        $container.removeClass('menu-open');
      } else {
        $('.menu-open').removeClass('menu-open');
        $container.addClass('menu-open');
      }

      // only allow 1 expandable menu to be open at a time
      if ($expandableMenu.hasClass('open') && !$moreDropdown.hasClass('open')) {
        $expandableMenu.removeClass('open');
      }

      if (!$moreDropdown.hasClass('open')) {
        $moreDropdown.addClass('open');
        $html.trigger('more-list-open');
      }

      const handleOutsideClick = (e) => {
        if (!$moreDropdown.is(e.target) && $moreDropdown.has(e.target).length === 0) {
          if($moreDropdown.hasClass('open')) {
            $container.removeClass('menu-open');
            $moreDropdown.removeClass('open');
            $body.off('click.moremenu');
          }
        }
      };
      $body.on('click.moremenu', handleOutsideClick);
    });
  }

  $(function () {
    handleExpandableMenu();
  });

};
