import $ from 'jquery';
import getURLParams from "./src/helpers/getURLParams";

export default () => {
  var router = new Navigo(location.protocol + "//" + location.hostname, false);

  function reloadAds() {
    if( TauntonUserConsent.user.previousAdConsent() ) {
      googletag.pubads().refresh();
    }
    else {
      googletag.pubads().setRequestNonPersonalizedAds(1).refresh();
    }
  }

  $(function() {

    // used for the full QA landing page and forum for the post list
    var qa_forum = {
      _wrapper: $('.community'),
      init: function() {
        this.routes();
        this.handleNav();
        this.handlePagination();
      },
      render: function(data) {
        var that = this;
        if(typeof data.items !== 'undefined' && typeof data.items.data !== 'undefined') {
          if(data.items.data.length) {
            var listTemplate = _.template($('#qa-list').html());

            $('.community__tab-content').replaceWith(listTemplate(data));

          } else {
            var emptyResultsTemplate = _.template($('#forum-empty-results').html());

            $('.community__tab-content').replaceWith(emptyResultsTemplate());

          }

        }
        if(typeof data.items !== 'undefined' && typeof data.items.total_pages !== 'undefined') {
          var queryParams = that.getParams(window.location.search),
            paginationTemplate = _.template($('#ajax-pagination').html());

          // current page is not present on preloaded content
          if(typeof data.items.current_page === 'undefined') {
            data.items.current_page = 1;
          }

          data.items.previous_page = location.pathname + '?paged=' + (parseInt(data.items.current_page, 10) - 1);
          data.items.next_page = location.pathname + '?paged=' + (parseInt(data.items.current_page, 10) + 1);

          if(queryParams.sort !== null) {
            data.items.previous_page += '&sort=' + queryParams.sort;
            data.items.next_page += '&sort=' + queryParams.sort;
          }

          $('.community__pagination').html(paginationTemplate(data));
        } else {
          $('.community__pagination').html('');
        }
      },
      request: function(query, callback) {
        var $wrapper = this._wrapper;

        $.ajax({
          type:'GET',
          url: '/wp/wp-admin/admin-ajax.php',
          data: query,
          dataType:'json',
          success: callback,
          beforeSend: function() {
            $wrapper.addClass('loading');
          },
          complete: function(){
            $wrapper.removeClass('loading');
          },
          error: function(request, status, error) {
            var errorTemplate = _.template($('#forum-results-error').html());

            $('.community__tab-content').replaceWith(errorTemplate());
            console.log(status);
            console.log(error);
          },
          timeout: 5000
        });
      },
      getParams: function(query) {
        var params = {};
        if (typeof URLSearchParams === 'function') {
          var urlParams = new URLSearchParams(query);
          params.sort = urlParams.get('sort');
          params.paged = urlParams.get('paged');
        } else {
          params.sort = getURLParams(query, 'sort');
          params.paged = getURLParams(query, 'paged');
        }
        return params;
      },

      /**
       * Base: /wp/wp-admin/admin-ajax.php?action=elastic_ajax_qa_search
       * Active: &sort=active
       * Pagination: &paged=(int)
       * New Questions: &sort=new
       * Most Popular: &sort=popular
       */
      routes: function() {
        var defaultRequest = 'action=elastic_ajax_qa_search',
          $wrapper = this._wrapper,
          $tabsWrapper = $('.community__tabs__wrapper'),
          $tabsFilter = $('.community__tabs--filter'),
          $tabsFilterLabel = $('.community__tabs--filter__label'),
          $current = $('.community__tabs--select__current'),
          that = this;

        // routes sort by recent activity by default
        router.on('/qa', function(params, query) {
          var queryParams = that.getParams(window.location.search),
            request = defaultRequest;

          $tabsWrapper.removeClass('hidden');
          $tabsFilter.addClass('hidden');

          // set active classes on nav
          $wrapper.find('.active').removeClass('active');

          if(queryParams.sort === 'popular') {
            request = 'action=ga_most_popular_questions';
            $('[data-qa-sort="' + queryParams.sort + '"]').addClass('active');
            $current.text($wrapper.find('.active').first().text());
          } else {
            if(queryParams.paged !== null && queryParams.paged !== 1) {
              request += '&paged=' + queryParams.paged;
            }

            if(queryParams.sort !== null) {
              request += '&sort=' + queryParams.sort;
              $('[data-qa-sort="' + queryParams.sort + '"]').addClass('active');
              $current.text($wrapper.find('.active').first().text());

            } else {
              request += '&sort=active';
              $('[data-qa-sort="recent"]').addClass('active');
              $current.text($wrapper.find('.active').first().text());
            }
          }

          that.request(request, function(res) {
            that.render({items: res});
          });

        }).resolve();

        router.on('/qa/:slug', function(params, query) {
          var queryParams = that.getParams(window.location.search),
            request = defaultRequest;

          // filters only provide the recent activity view
          request += '&sort=active';

          $tabsFilter.removeClass('hidden');
          $tabsWrapper.addClass('hidden');

          // set active classes on nav
          $wrapper.find('.active').removeClass('active');

          if(queryParams.paged !== null && queryParams.paged !== 1) {
            request += '&paged=' + queryParams.paged;
          }

          if(params.slug) {
            request += '&community_category=' + params.slug;
          }

          that.request(request, function(res) {
            that.render({items: res});

            if(typeof res.data !== 'undefined' && res.data.length > 0 && typeof res.data[0].category !== 'undefined') {
              $tabsFilterLabel.html('Category: ' + res.data[0].category);
            }
          });

        }).resolve();

      },
      handleNav: function() {
        var $wrapper = this._wrapper,
          $select = $('.community__tabs--select__options');

        $wrapper.on('click', '.community__tabs a', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);
          reloadAds();
        });

        $wrapper.on('click', '.community__tabs--select__options a', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);

          $select.removeClass('open');
          reloadAds();
        });

        $wrapper.on('click', '.community__column--category a', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);
          reloadAds();
        });

        $wrapper.on('click', 'a.community__tabs--filter__close', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);
          reloadAds();
        });

      },
      handlePagination: function() {
        var $wrapper = this._wrapper;

        $wrapper.on('click', '.community__pagination a', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);
          reloadAds();

          if($this.hasClass('isDisabled') === false) {
            $('html, body').animate({
              scrollTop: $wrapper.offset().top - 350
            }, 1000);
          }

        });
      }
    };

    // module that appears on home page and single layout
    var recent_qa = {
      _wrapper: $('.module--recent-qa__content'),
      init: function() {
        this.routes();
      },
      render: function(data) {
        var $wrapper = this._wrapper;

        if(typeof data.items !== 'undefined') {
          if(data.items.length) {
            var listTemplate = _.template($('#recent-qa').html());

            $wrapper.html(listTemplate(data));

          }

        }
      },
      request: function(query, callback) {
        $.ajax({
          type:'GET',
          url: '/wp/wp-admin/admin-ajax.php',
          data: query,
          dataType:'json',
          success: callback,
          error: function(request, status, error) {
            console.log(status);
            console.log(error);
          },
          timeout: 5000
        });
      },

      /**
       * Base: /wp/wp-admin/admin-ajax.php?action=recent_question_replies
       * Number of Articles: &limit=(int) - default 10
       */
      routes: function() {
        var that = this;

        // routes sort by recent activity by default
        router.on({
          '/article/*': function () {
            that.setContent(6);
          },
          '/question/*': function () {
            that.setContent(6);
          },
          '/collection/*': function () {
            that.setContent(6);
          },
          '/green-homes/*': function () {
            that.setContent(6);
          },
          '/green-basics/*': function () {
            that.setContent(6);
          },
          '/cad/detail/*': function () {
            that.setContent(6);
          },
          '/sponsored/*': function () {
            that.setContent(6);
          },
          '/sponsored': function () {
            that.setContent(6);
          }
        }).resolve();

      },
      setContent: function(count) {
        var request = 'action=recent_question_replies',
          limit = 10,
          that = this;

        if(typeof count !== 'undefined') {
          limit = parseInt(count, 10) || 10;
        }

        request += '&limit=' + limit;

        that.request(request, function(res) {
          that.render({items: res});
        });
      }
    };

    // module that appears on home page and single layout
    var recent_comments = {
      _wrapper: $('.module--recent-comments__content'),
      // this has no init method to register routes because
      // the route is shared with the recent QA feed.
      render: function(data) {
        var $wrapper = this._wrapper;

        if(typeof data.items !== 'undefined') {
          if(data.items.length) {
            var listTemplate = _.template($('#recent-comments').html());

            $wrapper.html(listTemplate(data));

          }

        }
      },
      request: function(query, callback) {
        $.ajax({
          type:'GET',
          url: '/wp/wp-admin/admin-ajax.php',
          data: query,
          dataType:'json',
          success: callback,
          error: function(request, status, error) {
            console.log(status);
            console.log(error);
          },
          timeout: 5000
        });
      },
      setContent: function(count) {

        var wrapper = this._wrapper,
          request = 'action=recent_article_comments',
          limit = 10,
          that = this;

        if(typeof count !== 'undefined') {
          limit = parseInt(count, 10) || 10;
        }

        if (typeof wrapper.data('cat-id') !== 'undefined') {
          request = 'action=recent_category_comments&cat_id=' + wrapper.data('cat-id');
          limit = 5;
        }

        request += '&limit=' + limit;

        that.request(request, function(res) {
          that.render({items: res});
        });
      }
    };

    var detail_library = {
      _wrapper: $('.detail-library--category'),
      init: function() {
        this.routes();
        this.handlePagination();
      },
      render: function(data) {
        if(typeof data.items !== 'undefined' && typeof data.items.data !== 'undefined') {
          if(data.items.data.length) {
            var listTemplate = _.template($('#detail-library-category').html());

            $('.detail-library--category__content').replaceWith(listTemplate(data));

          }

        }
        if(typeof data.items !== 'undefined' && typeof data.items.total_pages !== 'undefined') {
          var paginationTemplate = _.template($('#ajax-pagination').html());

          // current page is not present on preloaded content
          if(typeof data.items.current_page === 'undefined') {
            data.items.current_page = 1;
          }

          data.items.previous_page = location.pathname + '?paged=' + (parseInt(data.items.current_page, 10) - 1);
          data.items.next_page = location.pathname + '?paged=' + (parseInt(data.items.current_page, 10) + 1);

          $('.detail-library--category__pagination').html(paginationTemplate(data));
        }
      },
      request: function(query, callback) {
        var $wrapper = this._wrapper;

        $.ajax({
          type:'GET',
          url: '/wp/wp-admin/admin-ajax.php',
          data: query,
          dataType:'json',
          success: callback,
          beforeSend: function() {
            $wrapper.addClass('loading');
          },
          complete: function(){
            $wrapper.removeClass('loading');
          },
          error: function(request, status, error) {
            console.log(status);
            console.log(error);
          },
          timeout: 5000
        });
      },
      getParams: function(query) {
        var params = {};
        if (typeof URLSearchParams === 'function') {
          var urlParams = new URLSearchParams(query);
          params.cad_category = urlParams.get('cad_category');
          params.paged = urlParams.get('paged');
        } else {
          params.cad_category = getURLParams(query, 'cad_category');
          params.paged = getURLParams(query, 'paged');
        }
        return params;
      },
      /**
       * Base: /wp/wp-admin/admin-ajax.php?action=elastic_ajax_search&post_type=cad
       * Pagination: &paged=(int)
       * Category: &cad_category=kitchen-and-bath-package
       */
      routes: function() {
        var defaultRequest = 'action=elastic_ajax_search&post_type=cad',
          $wrapper = this._wrapper,
          category = $wrapper.data('cad_category'),
          that = this;

        // routes sort by recent activity by default
        router.on('/detail-library/:slug', function(params, query) {
          var queryParams = that.getParams(window.location.search),
            request = defaultRequest;

          if(category) {
            request += '&cad_category=' + category;
            defaultRequest += '&cad_category=' + category;
          }

          if(queryParams.paged !== null && queryParams.paged !== 1) {
            request += '&paged=' + queryParams.paged;
          }

          if(request === defaultRequest || request === defaultRequest + '&paged=1') {
            that.render({items: GBA.page.preload_detail_lib});
          } else {
            that.request(request, function(res) {
              that.render({items: res});
            });
          }

        }).resolve();

      },
      handlePagination: function() {
        var $wrapper = this._wrapper;

        $wrapper.on('click', '.detail-library--category__pagination a', function(e) {
          var $this = $(this);
          e.preventDefault();
          router.navigate($this.attr('href'), true);

          if($this.hasClass('isDisabled') === false) {
            $('html, body').animate({
              scrollTop: $wrapper.offset().top - 100
            }, 1000);
          }

        });
      }
    };

    qa_forum.init();
    recent_qa.init();
    detail_library.init();

    // routes sort by recent activity by default
    router.on('/', function () {
      // handle the search page use case since it also shares
      // a pathname with the home page and just appends a query string.
      var search_term = null;
      if (typeof URLSearchParams === 'function') {
        var urlParams = new URLSearchParams(window.location.search);
        search_term = urlParams.get('s');
      } else {
        search_term = getURLParams(window.location.search, 's');
      }

      if(search_term === null) {
        recent_qa.setContent(5);
        recent_comments.setContent(5);
      }
    }).resolve();

    router.on('/expert-exchange/:slug',function() {
      recent_comments.setContent(5);
    } ).resolve();

  });

};
