import $ from 'jquery';

export default () => {

  GBA.marketing = {};

  $(function() {

    import('./src/user-data/user-get').then(({default: userGet}) => {
      if (userGet('gba_newsletter_signed_up')) {
        $('.module--email-cta').hide();
      }
    });

    //Process Email Interstitial
    GBA.scripts.emailinterstitial = function () {
      var $newsinterstitialform = $('#newsletter-signup-interstitial');
      $newsinterstitialform.validate({
        onkeyup: false,
        onfocus: false,
        onsubmit: true,
        rules: {
          email: {
            required: true,
            email: true
          },
        },
        messages: {
          email: {
            required: "Please enter your email",
            email: "Emails must contain @ and ."
          },
        },
        errorPlacement: function(error, element) {
          error.insertBefore(element);
        },
        submitHandler: function(form) {
          // Show the loading state
          $('#enews-interstitial-submit').parent().parent().addClass('loading');

          import('./src/form-submit/reCaptchaValidate').then(({default: validate}) => {
            validate(form.dataset.placement)
            .then((result) => {
              import('./src/form-submit/process-email-form').then(({default: processForm}) => {
                processForm(form).then((result) => {
                  $('#newsletter-signup-interstitial').hide(250, function() {
                    $('.enews-interstitial__right-inner__thankyou').show(250);
                  });
                })
                .catch((result) => {
                  $('#newsletter-signup-interstitial').hide(250, function() {
                    $('.enews-interstitial__right-inner__error').show(250);
                  });
                  console.log(result);
                });
              });
            })
            .catch((result) => {
              $(form).replaceWith('<p class="signup-error">Spammer Detected!</p>');
              console.log(result);
            });
          });
        }
      });
    }

    // GA Event Tracking
    $(document).on('click', '[data-subscribe]', function() {
      var action = {'placement': $(this).data('subscribe'),
      'target': $(this).attr('href')};
      $.publish('GBA.subscribe.click', action);
    });

    $(document).on('click', '[data-subscribe-renew]', function() {
      var action = {'placement': $(this).data('subscribe-renew'),
      'target': $(this).attr('href')};
      $.publish('GBA.subscriberenew.click', action);
    });
  });
};
