import {searchEndpoint} from './constants';
import renderResults from './render-results';
import modalPagination from './modal-pagination';

export default () => {
  const modal: HTMLElement = document.querySelector('.detail-library__modal__wrapper');
  const form: HTMLFormElement = document.querySelector('.detail-library__search__form');
  const modalForm: HTMLFormElement = document.querySelector('.detail-library__modal-search__form');
  const button: HTMLButtonElement = document.querySelector('.detail-library__modal__more');

  if(!modal || !form || !button || !modalForm) {
    return;
  }

  // Instantiate a new A11yDialog module
  let dialog = null;
  let nextPage = null;

  form.addEventListener('submit', (e: Event) => {
    const url: string = `${searchEndpoint}${encodeURIComponent(form.s.value.trim())}`;
    e.preventDefault();

    if(form?.s?.value?.trim()) {
      import('./init').then(module => {
        dialog = module.default();
        modal.classList.add('loading');
        button.setAttribute('disabled', 'disabled');
        if (dialog) {
          dialog.show();
        }
        return fetch(url);
      }).then(data => {
          return data.json();
        }).then(results => {
          renderResults(results);
          modal.classList.remove('loading');
          nextPage = modalPagination(results, modal, button);
      });
    }
  });

  button.addEventListener('click', (e) => {
    const url: string = `${searchEndpoint}${encodeURIComponent(form.s.value.trim())}`;
    e.preventDefault();

    if(nextPage) {
      modal.classList.add('loading');
      fetch(url + `&paged=${nextPage}`)
        .then(data => {
          return data.json();
        }).then(results => {
          renderResults(results);
          modal.classList.remove('loading');
          nextPage = modalPagination(results, modal, button);
        });
    }
  });

  modalForm.addEventListener('submit', (e: Event) => {
    e.preventDefault();

    if(modalForm?.s?.value?.trim() && (modalForm.s.value.trim() !== form.s.value.trim())) {
      modal.classList.add('loading');
      button.setAttribute('disabled', 'disabled');
      form.s.value = modalForm.s.value.trim();

      fetch(`${searchEndpoint}${encodeURIComponent(modalForm.s.value.trim())}`)
        .then(data => {
          return data.json();
        }).then(results => {
        const container: HTMLElement = document.querySelector('.detail-library__modal__results');
        container.innerHTML = '';
        renderResults(results);
        modal.classList.remove('loading');
        nextPage = modalPagination(results, modal, button);
      });
    }
  });
}
