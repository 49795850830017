export default () => {
  window.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('.inline-email-module');
    let i = 0;
    [].forEach.call(forms, (form) => {

      function formUpdate(msg) {
        form.innerHTML = msg;
      }
      function createError(name, msg) {
        let l = document.createElement("label");
        l.classList.add("inline-error");
        l.setAttribute("for", name);
        l.setAttribute("id",name+"-error");
        l.setAttribute("name","inline-error");
        l.innerHTML = msg;
        return l
      }
      function removeError(name) {
        let error = form.querySelector('#'+name+"-error");
        if (error) {
          error.remove();
        }
      }
      function removeErrors(){
        let errors = form.querySelectorAll('.inline-error');
        [].forEach.call(errors, (error) => {
          error.remove();
        });
      }

      i++;

      const eleEmail = form.querySelector('input[type=email]') as HTMLInputElement | null;

      eleEmail.setAttribute("id","inline-email-"+i);

      eleEmail.addEventListener('focus', (e) => {
        removeError(eleEmail.id);
      });

      form.addEventListener('submit', (e) => {
        e.preventDefault();

        removeErrors();
        // Temporary email validation
        if ( !eleEmail.value.match(/(.+)@(.+){2,}\.(.+){2,}/) ) {
          eleEmail.after(createError(eleEmail.id,"Email is required and must contain @ and ."));
          return false;
        }

        form.querySelector('.module--email-cta__submit').parentElement.classList.add('loading');
        form.querySelector('.module--email-cta__text').parentElement.style.display = "none";

        import('./reCaptchaValidate').then(({default: validate}) => {
          validate(form.dataset.placement)
          .then((result) => {
            import('./process-email-form').then(({default: processForm}) => {
              processForm(form).then((result) => {
                formUpdate('<p class="signup-thankyou">Thanks for signing up!</p>');
              })
              .catch((result) => {
                formUpdate('<p class="signup-error"><span>Sorry.</span> There was an error submitting your email.  Please contact customer service to sign up.</p>');
                console.log(result);
              });
            });
          })
          .catch((result) => {
            formUpdate('<p class="signup-error">Spammer Detected!</p>');
            console.log(result);
          });
        });
      });
    });

    const article = document.querySelector('.article__body__main');
    if(article) {
      const articleForm = article.querySelector('.module--email-cta');
      const midBody = document.querySelector('.article__body__main > p:nth-of-type(4)');
      if(midBody && articleForm) {
        midBody.insertAdjacentElement('afterend', articleForm);
      }
    }

  });
}
