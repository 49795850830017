import $ from "jquery";

const calcWidth = () => {
  let navWidth = 0,
    moreLinksCount = 0;

  const $moreMenu = $('#main-roll-up-list .more'),
    spaceEaters = [
      $('.siteheader__nav').outerWidth(true),
      $moreMenu.outerWidth(true),
      40 // give it more breathing room
    ],
    $menuLinks = $('#main-roll-up-list > li:not(.more)'),
    $moreMenuList = $('#main-roll-up-list .more > ul');

  $menuLinks.each(function() {
    navWidth += $(this).outerWidth(true);
  });

  if(navWidth === 0) {
    return;
  }

  let availableSpace = spaceEaters.reduce(function(item, currentVal) {
    return item - currentVal;
  });

  while (navWidth > availableSpace) {
    var lastItem = $('#main-roll-up-list > li:not(.more)').last(),
      lastItemWidth = lastItem.outerWidth(true);

    if(!lastItem.length || !lastItemWidth) {
      break;
    }

    lastItem.attr('data-width', lastItemWidth);
    navWidth -= lastItem.outerWidth(true);
    lastItem.prependTo($moreMenuList);
    moreLinksCount++;
  }

  if (moreLinksCount !== 0) {
    $moreMenu.css('display','inline-block');
  } else {
    $moreMenu.css('display','none')
  }

}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const windowWidth = window.matchMedia('(min-width: 701px)');

    if(windowWidth.matches) {
      calcWidth();
    }
  });
}

